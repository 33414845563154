export default function Header(params) {
    return (
        <header className="header">
            <div className="container">
            <a  rel="noopener noreferrer"  href="/">
                <img
                    className="header_logo2"
                    src={
                        process.env.PUBLIC_URL + "/logo2.png"
                    }
                    alt=""
                />
            </a>

            <a target="_blank" className="header_vakan" href="https://talent.mos.ru/all-vacancies/">
                Смотреть вакансии
            </a>
            </div>
        </header>
    );
}
