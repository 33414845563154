import { useState } from "react";
// экран приветствия
export default function Hellou({ etap, setEtape }) {
    // счётчик состояния анимации
    const [couners, setcouners] = useState(1);

    // с небольшой задержкой запускаем счётчик шагов
    setTimeout(() => {
        setcouners(couners + 1);
    }, 500);
    return (
        <section className="hellou">
            <div className="container">
                <div className="hellou_body">
                    <div className="hellou_body_icon">
                        <div className="hellou_body_icon_box">
                            {couners >= 2 && (
                                <div className="hellou_body_icon_box_item e1">
                                    <img
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "/img/hellou/chel1.png"
                                        }
                                        alt=""
                                    />
                                </div>
                            )}
                            {couners >= 3 && (
                                <div className="hellou_body_icon_box_item e2">
                                    <img
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "/img/hellou/chel2.png"
                                        }
                                        alt=""
                                    />
                                </div>
                            )}

                            {couners >= 3 && (
                                <div className="hellou_body_icon_box_item e3">
                                    <img
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "/img/hellou/chel3.png"
                                        }
                                        alt=""
                                    />
                                </div>
                            )}

                            {couners >= 1 && (
                                <div className="hellou_body_icon_box_hint">
                                    ?
                                    <span className="hellou_body_icon_box_hint_span">
                                        Ты также
                                        неравнодушен к
                                        Москве? Попробуй
                                        себя в роли разных
                                        специалистов и
                                        узнай, что именно
                                        вдохновляет тебя
                                        делать город лучше
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="hellou_body_text">
                        <h1>
                            узнай,
                            <br />кем ты мог бы стать <br />{" "}
                            в команде Москвы

                        </h1>
                        <p>
                            Сотрудники Правительства Москвы
                            решают огромное количество
                            задач, чтобы каждый день делать
                            столицу еще технологичнее,
                            быстрее, уютнее и красивее. 
                        </p>

                        <button
                            onClick={() => setEtape(!etap)}
                            className="hellou_body_button"
                        >
                            Попробовать!
                        </button>
                    </div>
                </div>
            </div>
            <div className="fon">
                <img
                    className="fon_hellou"
                    src={
                        process.env.PUBLIC_URL +
                        "/img/hellou/fon.png"
                    }
                    alt=""
                />
            </div>
        </section>
    );
}
