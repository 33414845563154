import {
    motion,
} from "framer-motion";
import { AniBot, AniLeft, AniRight, AniRigtRevert, AniSceil, AniTop } from "./animation";
import { useState } from "react";


// экран со спортсменом
export default function Sportsmen({ its, sost, setSost }) {
    // переменная состояния видимости экрана 

    const [visibles, setvisibles] = useState(false);
    
    const variants = {
        visible: { background: "#FD7794" },
        hidden: { background: "#FD7794" },
    };

    if (!visibles) {
        function onAni() {
            setTimeout(() => {
                setSost(true);
                setvisibles(true);
            }, 500);
        }

        let options = {
            threshold: [0.5],
        };
        let observer = new IntersectionObserver(
            onAni,
            options
        );
        let elements = document.querySelectorAll(".ecran");
        for (let elm of elements) {
            observer.observe(elm);
        }
    }
    let animatTime1 = 2;
    let animatTime2 = animatTime1 * 0.5;
    let animatTime3 = animatTime1 * 3;
    
    return (
        <motion.div
            animate={!sost ? "visible" : "hidden"}
            variants={variants}
            transition={{
                duration: 1,
            }}
            className="ecran sportsmen"
        >
            {visibles && (
                <>
                    <AniBot 
                    time={animatTime1}
                        state={sost}
                        clas="e1"
                        end={"30%"}
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e2/home1.png"
                                }
                                alt=""
                            />
                        }
                    />

                    <AniBot
                     time={animatTime1}
                        state={sost}
                        clas="e2"
                        end={"30%"}
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e2/home2.png"
                                }
                                alt=""
                            />
                        }
                    />
                    <AniBot
                     time={animatTime1}
                        state={sost}
                        clas="e3"
                        end={"30%"}
                        child={
                            <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e2/home3.png"
                            }
                            alt=""
                        />
                        }
                    />
                   <AniRigtRevert
                   time={animatTime1}
                   state={sost}
                   clas="e4"
                   child={
                       <img
                       src={
                           process.env.PUBLIC_URL +
                           "/img/e2/home4.png"
                       }
                       alt=""
                   />
                    }
                   />
                    <AniTop
                    time={animatTime2}
                    state={sost}
                    clas="e5"
                    end={"18%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e2/home5.png"
                            }
                            alt=""
                        />
                    }
                    
                    />
                    <AniTop
                    time={animatTime2}
                    state={sost}
                    clas="e6"
                    end={"27%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e2/home6.png"
                            }
                            alt=""
                        />
                    }
                    
                    />
                    <AniLeft
                    time={animatTime2}
                    state={sost}
                    clas="e7"
                    end={"12%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e2/front1.png"
                            }
                            alt=""
                        />
                    }
                    
                    />
                    <AniLeft
                    time={animatTime2}
                    state={sost}
                    clas="e8"
                    end={"35%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e2/front2.png"
                            }
                            alt=""
                        />
                    }
                    
                    />
                    <AniSceil
                   time={animatTime1}
                   state={sost}
                   clas="e9"
                   child={
                       <img
                       src={
                           process.env.PUBLIC_URL +
                           "/img/e2/front3.png"
                       }
                       alt=""
                   />
                    }
                   />
                    <AniRight
                    time={animatTime2}
                    state={sost}
                    clas="e10"
                    end={"13%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e2/front4.png"
                            }
                            alt=""
                        />
                    }
                    
                    />
                    <AniBot 
                    time={animatTime2}
                        state={sost}
                        clas="e11"
                        end={"30%"}
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e2/front5.png"
                                }
                                alt=""
                            />
                        }
                    />
                  <AniLeft
                    time={animatTime2}
                    state={sost}
                    clas="e12"
                    end={"13%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e2/front5.png"
                            }
                            alt=""
                        />
                    }
                    
                    />
                    <AniLeft
                    time={animatTime2}
                    state={sost}
                    clas="e13"
                    end={"-0.5%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e2/front6.png"
                            }
                            alt=""
                        />
                    }
                    
                    />
                    <AniRight
                    time={animatTime2}
                    state={sost}
                    clas="e14"
                    end={"5%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e2/front6.png"
                            }
                            alt=""
                        />
                    }
                    
                    />
                    <AniLeft
                    time={animatTime2}
                    state={sost}
                    clas="e15"
                    end={"24%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e2/front7.png"
                            }
                            alt=""
                        />
                    }
                    
                    />
                    
                    {/* подложка */}
                    <motion.div
                        initial={{ background: "#FD7794" }}
                        animate={{ background: "#FD7794" }}
                        transition={{
                            duration: 1,
                        }}
                        className="podloj"
                    ></motion.div>
                </>
            )}
        </motion.div>
    );
}
