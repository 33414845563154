import { useEffect, useState } from "react";
import { Otvet } from "../data/data";


let big = 0;
let tr =0;
export function EndResult({ formInfo }) {

    // полученый результат разбиваем на категории 
    let a = Object.entries(formInfo).reduce(
        (acc, [k, v]) => (
            (acc[v] = acc[v] || []).push(k), acc
        ),
        {}
    );

    console.log(a);
        // вычисляем какой категории выбранно наибольшее количество ответов
    for (let i in a) {
        if (a[i].length>big) {
            // console.log(big);
            // console.log(a[i].length);
            big=a[i].length;
            tr=i;
        }
    }
    console.log(tr);
    return (
        <div className="rezult">
            <div className="container">
                <div className="rezult_left">
                    <p className="rezult_pohval">
                        Отлично, ты справился со всеми
                        задачами!{" "}
                    </p>
                    <h2>
                        А теперь мы готовы сообщить тебе
                        результаты — что именно вдохновляет
                        тебя в работе.
                    </h2>
                    <div className="rezult_otvet_shedov">
                        <div className="rezult_otvet">
                            <div className="rtrt" >
                                <p dangerouslySetInnerHTML={{ __html: Otvet[tr - 1].text}}>

                                </p>
                            </div>
                        </div>
                    </div>
                    <p className="rezult_pohval" dangerouslySetInnerHTML={{ __html: Otvet[tr - 1].textMin}}>
                        
                    </p>
                    <p className="rezult_pohval">
                        <b>
                            {Otvet[tr-1].tittle}
                        </b>{" "}
                    </p>
                    <a className="link" type="_blank" href="https://talent.mos.ru/all-vacancies/">
                    Присоединиться!
                    </a>
                </div>
                <div className="rezult_right">
                   
                    <img
                    className="hh"
                    src={
                        process.env.PUBLIC_URL +
                        "/img/end/hh.png"
                    }
                    alt=""
                />
                </div>
            </div>
            <div className="fon">
                <img
                    className="fon_body"
                    src={
                        process.env.PUBLIC_URL +
                        "/img/end/fon.png"
                    }
                    alt=""
                />
                
            </div>
        </div>
    );
}
