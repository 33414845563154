import { motion } from "framer-motion";
import {
    AniAppToBot,
    AniBot,
    AniLeft,
    AniRight,
    AniRigtRevert,
    AniTop,
} from "./animation";
import { useState } from "react";

export default function Hozaistv({ its, sost, setSost }) {
    // переменная состояния видимости экрана 

    const [visibles, setvisibles] = useState(false);

    const variants = {
        visible: { background: "#004427" },
        hidden: { background: "#8ECEAF" },
    };
    // проверка сотояния видимости чтобы предотвратить постоянный вызов функции видимости 

    if (!visibles) {
        function onAni() {
            setTimeout(() => {
                setSost(true);
                setvisibles(true);
            }, 500);
        }

        let options = {
            threshold: [0.5],
        };
        let observer = new IntersectionObserver(
            onAni,
            options
        );
        let elements = document.querySelectorAll(".ecran");
        for (let elm of elements) {
            observer.observe(elm);
        }
    }
    // временные интервалы

    let time = 1;
    let time2 = time * 2;
    let time3 = time * 3;
    let time4 = time * 4;
    let time5 = time * 5;
    return (
        <motion.div
            animate={!sost ? "visible" : "hidden"}
            variants={variants}
            transition={{
                duration: 1,
            }}
            className="ecran hozaistv"
        >
            {/* начинаем отрисовку элемента после подтверждения видимости экрана */}

            {visibles && (
                <>
                    <AniBot
                        time={time}
                        state={sost}
                        end={"3%"}
                        clas="e1"
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e1.png"
                                }
                                alt=""
                            />
                        }
                    />
                    <AniBot
                        time={time}
                        state={sost}
                        end={"3%"}
                        clas="e2"
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e2.png"
                                }
                                alt=""
                            />
                        }
                    />
                     <AniAppToBot
                        time={time}
                        state={sost}
                        clas="e3"
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e3.png"
                                }
                                alt=""
                            />
                        }
                    />
                    <AniRigtRevert
                        time={time3}
                        state={sost}
                        clas="e4"
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e4.png"
                                }
                                alt=""
                            />
                        }
                    />


                    <AniAppToBot
                        time={time2}
                        state={sost}
                        clas="e5"
                        child={
                           <>
                            <img className="vetr1 "
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e5.png"
                                }
                                alt=""
                            />
                            <img className="vetr2 vert"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e6.png"
                                }
                                alt=""
                            />
                           
                           </>
                        }
                    />
                    <AniAppToBot
                        time={time2}
                        state={sost}
                        clas="e5 e6"
                        child={
                           <>
                            <img className="vetr1 "
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e5.png"
                                }
                                alt=""
                            />
                            <img className="vetr2 vert"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e6.png"
                                }
                                alt=""
                            />
                           
                           </>
                        }
                    />
                    <AniAppToBot
                        time={time3}
                        state={sost}
                        clas="e5 e7"
                        child={
                           <>
                            <img className="vetr1 "
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e5.png"
                                }
                                alt=""
                            />
                            <img className="vetr2 vert"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e6.png"
                                }
                                alt=""
                            />
                           
                           </>
                        }
                    />
                    <AniAppToBot
                        time={time2}
                        state={sost}
                        clas="e8"
                        child={
                           <>
                           
                            <img 
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e7.png"
                                }
                                alt=""
                            />
                           
                           </>
                        }
                    />
                    <AniTop
                        time={time}
                        state={sost}
                        end={"3%"}
                        clas="e9"
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e8.png"
                                }
                                alt=""
                            />
                        }
                    />
                    <AniTop
                        time={time}
                        state={sost}
                        end={"10%"}
                        clas="e10"
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e8.png"
                                }
                                alt=""
                            />
                        }
                    />
                    <AniTop
                        time={time}
                        state={sost}
                        end={"12%"}
                        clas="e11"
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e8.png"
                                }
                                alt=""
                            />
                        }
                    />
                    <AniTop
                        time={time}
                        state={sost}
                        end={"18%"}
                        clas="e13"
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e8.png"
                                }
                                alt=""
                            />
                        }
                    />
                    <AniBot
                        time={time}
                        state={sost}
                        end={"3%"}
                        clas="e14"
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e9.png"
                                }
                                alt=""
                            />
                        }
                    />
                    <AniBot
                        time={time}
                        state={sost}
                        end={"3%"}
                        clas="e15"
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e7/e9.png"
                                }
                                alt=""
                            />
                        }
                    />
                    {/* подложка */}
                    <motion.div
                        initial={{ background: "#00753D" }}
                        animate={{ background: "#00753D" }}
                        transition={{
                            duration: 1,
                        }}
                        className="podloj"
                    ></motion.div>
                </>
            )}
        </motion.div>
    );
}
