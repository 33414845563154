import { useState } from "react";
// фон экрана со строителем
export default function Sroitel({ its, sost, setSost }) {
    // переменная состояния видимости экрана 
    const [visible, setvisible] = useState(false);
    // счётчик времени
    const [sec, setSec] = useState(1);
    // проверка сотояния видимости чтобы предотвратить постоянный вызов функции видимости 
    if (!visible) {
        function onAni(entry) {
            setTimeout(() => {
                setSost(true);
                setvisible(true);
            }, 500);
        }

        let options = {
            threshold: [0.5],
        };
        let observer = new IntersectionObserver(
            onAni,
            options
        );
        let elements = document.querySelectorAll(".ecran");
        for (let elm of elements) {
            observer.observe(elm);
        }
    }

    if (visible) {
        setTimeout(() => {
            setSec(sec + 1);
        }, 500);

    }

    return (
        <div className="ecran stroitel">
            {/* <img
                className="stroitel_oblako e1"
                src={process.env.PUBLIC_URL + "/img/e1/oblaco.png"}
                alt=""
            /> */}
            {/* начинаем отрисовку элемента после подтверждения видимости экрана */}
            {visible && (
                <>
                    {sec > 1 && (
                        <>
                            <img
                                className="stroitel_oblako e2"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e1/oblaco.png"
                                }
                                alt=""
                            />
                            <img
                                className="stroitel_oblako e3"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e1/oblaco.png"
                                }
                                alt=""
                            />
                            <img
                                className="stroitel_oblako e4"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e1/oblaco.png"
                                }
                                alt=""
                            />
                            <img
                                className="stroitel_home e1 "
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e1/hom1.png"
                                }
                                alt=""
                            />
                            <img
                                className="stroitel_home e2 "
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e1/hom2.png"
                                }
                                alt=""
                            />
                            <img
                                className="stroitel_home e3 "
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e1/hom3.png"
                                }
                                alt=""
                            />
                            <img
                                className="stroitel_home e4 "
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e1/hom4.png"
                                }
                                alt=""
                            />
                            <img
                                className="stroitel_home e5 "
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e1/hom5.png"
                                }
                                alt=""
                            />
                            <img
                                className="stroitel_home e6 "
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e1/hom6.png"
                                }
                                alt=""
                            />
                            <img
                                className="stroitel_home e7 "
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e1/hom7.png"
                                }
                                alt=""
                            />
                            {sec > 2 && (
                                <>
                                    <img
                                        className="stroitel_home e8 "
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "/img/e1/hom8.png"
                                        }
                                        alt=""
                                    />

                                    <img
                                        className="stroitel_home e81  "
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "/img/e1/hom8.1.png"
                                        }
                                        alt=""
                                    />
                                    <img
                                        className="stroitel_home e82  "
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "/img/e1/hom8.2.png"
                                        }
                                        alt=""
                                    />
                                    <img
                                        className="stroitel_home e9  "
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "/img/e1/hom9.png"
                                        }
                                        alt=""
                                    />
                                     <img
                                        className="stroitel_home e91  "
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "/img/e1/hom9.1.png"
                                        }
                                        alt=""
                                    />
                                    <img
                                        className="stroitel_home e92  "
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "/img/e1/hom8.2.png"
                                        }
                                        alt=""
                                    />
                                </>
                            )}

                        </>
                    )}
                </>
            )}
        </div>
    );
}
