import { useState } from "react";
import Header from "../bloc/header";
import Hellou from "../bloc/hellou";
import Form from "../bloc/form";
import { motion } from "framer-motion";
import { EndResult } from "../bloc/end";

// пременная по записи ответов
let formInfo = {};

export default function Main(params) {
    // состояние текущего вопроса
    const [etape, setetape] = useState(true);

    // состояние процесса завершён он или нет
    const [end, setend] = useState(false);


    
    // let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    // document.documentElement.style.setProperty('--vh', `${vh}px`);
    return (
        <>
            <Header />
            <main className="main">
                <motion.div className="cube">
                    <motion.div
                        initial={
                            etape
                                ? { rotateX: "0deg" }
                                : {
                                      rotateX: "90deg",
                                      translateY: "-100vh",
                                  }
                        }
                        animate={
                            !etape
                                ? {
                                      rotateX: "90deg",
                                      translateY: "-100vh",
                                  }
                                : { rotateX: "0deg" }
                        }
                        transition={{
                            duration: !etape ? 1 :0.5,
                        }}
                        className="cube_q"
                    >   
                    {end? <EndResult formInfo={formInfo}/>:<Hellou
                            etap={etape}
                            setEtape={setetape}
                        />

                    }
                        
                    </motion.div>
                    <motion.div
                        className="cube_w"
                        initial={
                            etape
                                ? {
                                      rotateX: "-90deg",
                                      translateY: "50vh",
                                  }
                                : {
                                      rotateX: "0deg",
                                      translateY: "0vh",
                                  }
                        }
                        animate={
                            !etape
                                ? {
                                      rotateX: "0deg",
                                      translateY: "0vh",
                                  }
                                : {
                                      rotateX: "-90deg",
                                      translateY: "50vh",
                                  }
                        }
                        transition={{
                            duration:etape ? 1 :0.5,
                        }}
                    >
                        <Form
                        end={end} setend={setend}
                        formInfo={formInfo}
                            setetape={setetape}
                        />
                    </motion.div>
                </motion.div>
                {/* <div className={etape ?"cube":"cube on"}>
                    <div className="flip">
                        <Hellou
                        etap={etape}
                        setEtape={setetape}
                        />
                    
                    </div>
                    <div className="flop">
                        
                    </div>
                </div> */}
            </main>
        </>
    );
}
