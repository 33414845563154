export const Wopr = [
    {
        name:"sportsmen",
        
        color:"#D70A3F",
        wopros: "Представь, что ты занимаешься развитием спорта и спортивной инфраструктуры в команде Москвы. В каком из проектов хотел бы поучаствовать?",
        war:[
            {
                text:"Строительство нового спортивного комплекса для жителей разных возрастов",
                bal:1,
            },
            {
                text:"Организация масштабных спортивных мероприятий:  матчи, фестивали, заезды",
                bal:3,
            },
            {
                text:"Спроектировал бы новые уличные площадки в жилых районах для бесплатных тренировок",
                bal:2,
            },
            {
                text:"Сдал бы нормативы ГТО, чтобы на своем примере показать горожанам, как важен здоровый образ жизни",
                bal:5,
            },
            {
                text:"Прошел бы всевозможные курсы для работников спортивной индустрии, чтобы стать универсальным специалистом",
                bal:4,
            },
            
        ]
    },
    {
        name: "stroitel",
        
        color:"#6F6F6F",
        wopros: "Представь, что ты работаешь в строительном комплексе Правительства Москвы. Вы с коллегами начинаете строительство новой магистрали. Какую часть проекта тебе было бы интересно реализовать?",
        war:[
            {
                text:"Я хочу подключиться к каждой части проекта и разобраться во всех деталях",
                bal:4,
            },
            {
                text:"Буду следить за сроками строительства, чтобы дорога появилась как можно скорее",
                bal:3,
            },
            {
                text:"Если трасса будет проходить рядом с лесом, спроектирую подземные переходы для диких животных",
                bal:1,
            },
            {
                text:"На этапе планирования проведу опрос жителей и узнаю, до каких мест в городе им важно добираться быстрее",
                bal:2,
            },
            {
                text:"Изучу лучший опыт и предложу решение, которое повысит эффективность проекта",
                bal:5,
            },
            
        ]
    },
    {
        name:"doctor",

        color:"#FF670B",
        wopros: "Ты работаешь в сфере социального развития столицы, и твоей команде впервые предстоит обновление поликлиники по новому стандарту. Для начала нужно распределить задачи на ближайший период. Какая из них была бы первой в списке?",
        war:[
            {
                text:"Предложил бы изменения в дизайне поликлиники, чтобы каждому пациенту было еще приятнее там находиться",
                bal:3,
            },
            {
                text:"Обновил бы медицинское оборудование для улучшения качества медицинских услуг",
                bal:2,
            },
            {
                text:"Проконтролировал бы организацию доступной среды для маломобильных групп населения",
                bal:1,
            },
            {
                text:"Прошел бы курс повышения квалификации, чтобы лучше разбираться в вопросе",
                bal:4,
            },
            {
                text:"Принял бы участие в исследовательских проектах, медицинских выставках и презентациях для обмена опытом",
                bal:5,
            },
            
        ]
    },
    {
        name:"istorik",

        color:"#663398",
        wopros: "Представь, что музеи, театры, культурные центры и выставки стали не только твоим досугом, но и работой, на которую ты с удовольствием приходишь. Что тебя больше всего вдохновляет?",
        war:[
            {
                text:"Встреча с интересными людьми: экскурсоводами, режиссерами, реставраторами, библиотекарями",
                bal:5,
            },
            {
                text:"Ремонт и реставрация объектов культурного наследия",
                bal:3,
            },
            {
                text:"Проведение творческих мастер-классов для жителей столицы",
                bal:2,
            },
            {
                text:"Организация новых экскурсионных маршрутов по столице",
                bal:1,
            },
            {
                text:"Общение с коллегами и участие в профессиональных мероприятиях",
                bal:4,
            },
            
        ]
    },
    {
        name:"voditel",

        color:"#98093F",
        wopros: "В Москве собираются запустить новый маршрут общественного наземного транспорта. Подумай, чем бы ты хотел заниматься в этом проекте?",
        war:[
            {
                text:"Организовал бы опрос среди жителей района и узнал, в каких точках им не хватает остановок",
                bal:2,
            },
            {
                text:"Разработал бы план нового маршрута и рассказал жителям о его преимуществах",
                bal:3,
            },
            {
                text:"Изучил бы проекты строительства новых современных остановок <br> и попробовал реализовать лучший опыт",
                bal:4,
            },
            {
                text:"Спроектировал бы новые подземные переходы рядом с остановками",
                bal:1,
            },
            {
                text:"Погрузился бы в каждый этап проекта, чтобы получить отличный опыт и делиться им с коллегами",
                bal:5,
            },
            
        ]
    },
    {
        name:"ekonomist",

        color:"#1833A0",
        wopros: "Ура, ты присоединился к комплексу экономической политики и имущественно-земельных отношений! В столице работает более 4 тысяч промышленных площадок. Что бы ты сделал для поддержания благоприятного инвестиционного климата?",
        war:[
            {
                text:"Нашел бы новые пути продвижения и развития брендов местных предпринимателей",
                bal:5,
            },
            {
                text:"Привлек бы инвестиции для развития промышленных площадок <br> и создания новых рабочих мест",
                bal:1,
            },
            {
                text:"Запустил бы программу развития новых московских брендов для удовлетворения спроса на продукцию",
                bal:2,
            },
            {
                text:"Изучил бы передовой опыт формирования инвестиционного климата и на его примере создал проект",
                bal:4,
            },
            {
                text:"Пригласил бы жителей на экскурсию по промышленным площадкам города, чтобы познакомить их с процессом производства",
                bal:3,
            },
            
        ]
    },
    {
        name:"hozaistvo",

        color:"#004427",
        wopros: "А теперь представь, что оказался в комплексе городского хозяйства и тебе предстоит благоустройство района. Какую задачу ты бы с удовольствием выполнил сам?",
        war:[
            {
                text:"Сделал бы упор на обновление детских площадок и установку скамеек для отдыха",
                bal:2,
            },
            {
                text:"Организовал бы строительство современной площадки для выгула собак",
                bal:1,
            },
            {
                text:"Предложил бы коллегам-архитекторам совместный проект <br> по обновлению фасадов зданий",
                bal:3,
            },
            {
                text:"Погрузился бы в историю района и представил свое видение его благоустройства",
                bal:5,
            },
            {
                text:"Изучил бы ландшафт местности и подобрал лучшее решение, опираясь на опыт коллег",
                bal:4,
            },
            
        ]
    },
   
];



export const Otvet=[
    {
        text:"Ты уделяешь большое внимание вопросам, связанным с качеством жизни, и готов помогать тем, кто нуждается в поддержке. Ты можешь попробовать себя в роли волонтера, наставника, преподавателя, менеджера социальных проектов для предпринимателей или специалиста в медицинской сфере. ",
        textMin:'Тебя, как и многих сотрудников Правительства Москвы, вдохновляет в работе социальная значимость выполняемых задач — и такие ты точно найдешь в нашей команде! Присоединяйся, чтобы вместе делать столицу еще лучше! ',
        tittle:"Ты — супергерой для жителей и всего города!"

        
    },
    {
        text:"Тебе важно получать обратную связь от жителей и понимать, что город становится еще комфортнее и лучше для каждого: твоих соседей, друзей, семьи — всех москвичей. Ты можешь попробовать себя в роли архитектора, специалиста по безопасности, юриста, библиотекаря или сотрудника управы, который общается с гражданами. ",
        textMin:"Тебя, как и многих сотрудников Правительства Москвы, вдохновляет работа на благо жителей — и такие задачи ты точно найдешь в нашей команде! Присоединяйся, чтобы вместе делать столицу еще лучше! ",
        tittle:"Ты — человек дела!"

        
    },
    {
        text:"Тебе важно превращать идеи в действия, а действия — в видимые изменения столицы. Выбирай сферу, в которой результаты твоих проектов будут заметны каждому жителю, например, создание новых маршрутов транспорта, разработка современных цифровых решений, организация фестивалей или строительство новых зданий.",
        textMin:'Тебя, как и многих сотрудников Правительства Москвы, вдохновляет видимый результат работы — и такие задачи ты точно найдешь в нашей команде! Присоединяйся, чтобы вместе делать столицу еще лучше! ',
        tittle:"Ты — творец!" 
    },
    {
        text:"Тебя мотивирует профессиональный рост и не пугают никакие вызовы — в них ты видишь новые возможности для себя и своей команды. Ты готов постоянно учиться и совершенствоваться, поэтому можешь выбирать сферы, связанные с инновациями, наукой, спортом или попробовать себя в роли руководителя новых цифровых проектов. ",
        textMin:'Тебя, как и многих сотрудников Правительства Москвы, вдохновляет в работе профессиональное развитие — и ты точно сможешь реализовать себя в нашей команде! Присоединяйся, чтобы вместе делать столицу еще лучше! ',

        tittle:"Ты —  мастер своего дела!"
        
    },
    {
        text: "Ты не просто работаешь, ты проживаешь свою работу. Тебе нравится то, что ты делаешь, и ты готов вкладывать свою энергию и страсть в каждый проект. Выбирай сферы, где нужно решать интересные и творческие задачи, например, театры и музеи, проведение масштабных мероприятий, связи с общественностью или работа с детьми.",
        textMin:'Тебя, как и многих сотрудников Правительства Москвы, вдохновляет интересная работа — и ты точно сможешь найти это в нашей команде! Присоединяйся, чтобы вместе делать столицу еще лучше!',

        tittle:"Ты — идеалист и ценитель!" 
    }
]

// export const Otvet=[
//     {
//         text:"Тебя, как и многих сотрудников Правительства Москвы, вдохновляет в работе социальная значимость выполняемых задач — и такие ты точно найдешь в нашей команде! <br/><br/>"+
//         "Теперь ты узнал, что тебя, как многих сотрудников Правительства Москвы, вдохновляет работа на благо жителей.<br/><br/>"+ 
//         "Ты — человек дела!"
        
//     },
//     {
//         text:"Тебя, как и многих сотрудников Правительства Москвы, вдохновляет работа на благо жителей — и такие задачи ты точно найдешь в нашей команде! <br/> <br/> "+
//         "Теперь ты узнал, что тебя, как многих сотрудников Правительства Москвы, вдохновляет работа на благо жителей.<br/><br/>"+ 
//         "Ты — идеалист и ценитель! <br/>"
        
//     },
//     {
//         text:"Тебя, как и многих сотрудников Правительства Москвы, вдохновляет видимый результат работы — и такие задачи ты точно найдешь в нашей команде! <br/> <br/> "+
//        "Теперь ты узнал, что тебя, как многих сотрудников Правительства Москвы, вдохновляет работа на благо жителей.<br/><br/>"+
//        "Ты —  мастер своего дела!<br/>"
//     },
//     {
//         text:"Тебя, как и многих сотрудников Правительства Москвы, вдохновляет в работе профессиональное развитие — и ты точно сможешь реализовать себя в нашей команде!<br/><br/>"+ 
//         "Теперь ты узнал, что тебя, как многих сотрудников Правительства Москвы, вдохновляет работа на благо жителей.  <br/> "+
//         "Ты — супергерой для жителей и всего города!"
        
//     },
//     {
//         text: "Тебя, как и многих сотрудников Правительства Москвы, вдохновляет интересная работа — и ты точно сможешь найти это в нашей команде!  <br/><br/>"+
//         "Теперь ты узнал, что тебя, как многих сотрудников Правительства Москвы, вдохновляет работа на благо жителей.<br/><br/>"+ 
//         "Ты — творец!<br/>"
//     }
// ]

// export const Otvet=[
//     {
//         text:"Ты — супергерой для жителей и всего города!<br/><br/>"+
//         "Ты уделяешь большое внимание вопросам, связанным с качеством жизни, и готов помогать тем, кто нуждается в поддержке. Ты можешь попробовать себя в роли волонтера, наставника, преподавателя, менеджера социальных проектов для предпринимателей или специалиста в медицинской сфере.<br/><br/>"+ 
//         "Тебя, как и многих сотрудников Правительства Москвы, вдохновляет в работе социальная значимость выполняемых задач — и такие ты точно найдешь в нашей команде!   "
        
//     },
//     {
//         text:"Ты — человек дела! <br/><br/>"+
//         "Тебе важно получать обратную связь от жителей и понимать, что город становится еще комфортнее и лучше для каждого: твоих соседей, друзей, семьи — всех москвичей. Ты можешь попробовать себя в роли архитектора, специалиста по безопасности, юриста, библиотекаря или сотрудника управы, который общается с гражданами.<br/><br/>"+ 
//         "Тебя, как и многих сотрудников Правительства Москвы, вдохновляет работа на благо жителей — и такие задачи ты точно найдешь в нашей команде!  <br/> "
        
//     },
//     {
//         text:"Ты — творец!<br/><br/>"+
//        "Тебе важно превращать идеи в действия, а действия — в видимые изменения столицы. Выбирай сферу, в которой результаты твоих проектов будут заметны каждому жителю, например, создание новых маршрутов транспорта, разработка современных цифровых решений, организация фестивалей или строительство новых зданий.<br/><br/>"+
//         "Тебя, как и многих сотрудников Правительства Москвы, вдохновляет видимый результат работы — и такие задачи ты точно найдешь в нашей команде!  <br/> "
//     },
//     {
//         text:"Ты — мастер своего дела!<br/><br/>"+
//         "Тебя мотивирует профессиональный рост и не пугают никакие вызовы — в них ты видишь новые возможности для себя и своей команды. Ты готов постоянно учиться и совершенствоваться, поэтому можешь выбирать сферы, связанные с инновациями, наукой, спортом или попробовать себя в роли руководителя новых цифровых проектов.<br/><br/>"+ 
//         "Тебя, как и многих сотрудников Правительства Москвы, вдохновляет в работе профессиональное развитие — и ты точно сможешь реализовать себя в нашей команде!  <br/> "
        
//     },
//     {
//         text:"Ты — идеалист и ценитель! <br/><br/>"+
//         "Ты не просто работаешь, ты проживаешь свою работу. Тебе нравится то, что ты делаешь, и ты готов вкладывать свою энергию и страсть в каждый проект. Выбирай сферы, где нужно решать интересные и творческие задачи, например, театры и музеи, проведение масштабных мероприятий, связи с общественностью или работа с детьми.<br/><br/>"+ 
//         "Тебя, как и многих сотрудников Правительства Москвы, вдохновляет интересная работа — и ты точно сможешь найти это в нашей команде!  <br/>"
//     }
// ]