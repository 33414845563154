import {
    motion,
} from "framer-motion";
import { AniBot, AniLeft, AniRight, AniRigtRevert, AniSceil, AniTop } from "./animation";
import { useState } from "react";



export default function Voditel({ its, sost, setSost }) {
    // переменная состояния видимости экрана 

    const [visibles, setvisibles] = useState(false);

    const variants = {
        visible: { background: "#98093F"},
        hidden: { background: "#FD7794" },
    };
    // проверка сотояния видимости чтобы предотвратить постоянный вызов функции видимости 

    if (!visibles) {
        function onAni() {
            setTimeout(() => {
                setSost(true);
                setvisibles(true);
            }, 500);
        }

        let options = {
            threshold: [0.5],
        };
        let observer = new IntersectionObserver(
            onAni,
            options
        );
        let elements = document.querySelectorAll(".ecran");
        for (let elm of elements) {
            observer.observe(elm);
        }
    }
    // временные интервалы
 
    let time = 1;
    let time2 = time*2;
    let time3 = time*3;
    let time4 = time*4;
    let time5 = time*5;
    return (
        <motion.div
            animate={!sost ? "visible" : "hidden"}
            variants={variants}
            transition={{
                duration: 1,
            }}
            className="ecran voditel"
        >
            {/* начинаем отрисовку элемента после подтверждения видимости экрана */}

            {visibles && (
                <>
                    <AniBot
                    
                    time={time3}
                    state={sost}
                    clas="e1"
                    end={"2%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e1.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={time5}
                    state={sost}
                    clas="e2"
                    end={"2%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e2.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={time}
                    state={sost}
                    clas="e3"
                    end={"2%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e3.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={time5}
                    state={sost}
                    clas="e4"
                    end={"2%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e4.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={7}
                    state={sost}
                    clas="e5"
                    end={"2%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e5.png"
                            }
                            alt=""
                        />
                    }
                    />
                     <AniBot
                    
                    time={time}
                    state={sost}
                    clas="e6"
                    end={"2%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e6.png"
                            }
                            alt=""
                        />
                    }
                    />
                     <AniBot
                    
                    time={time2}
                    state={sost}
                    clas="e7"
                    end={"-2%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e7.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={time2}
                    state={sost}
                    clas="e8"
                    end={"2%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e8.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={time2}
                    state={sost}
                    clas="e9"
                    end={"-1%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e9.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={5}
                    state={sost}
                    clas="e10"
                    end={"-1%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e10.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={6}
                    state={sost}
                    clas="e11"
                    end={"-1%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e11.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={7}
                    state={sost}
                    clas="e12"
                    end={"-1%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e12.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={time}
                    state={sost}
                    clas="e12"
                    end={"-1%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e12.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <div className={sost?"box_img e13 ":"box_img e13 bac"}>
                    <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e5/e13.png"
                            }
                            alt=""
                        />
                    </div>
                    
                    {/* подложка */}
                    <motion.div
                        initial={{ background: "rgb(253, 119, 148)" }}
                        animate={{ background: "#98093F" }}
                        transition={{
                            duration: 1,
                        }}
                        className="podloj"
                    ></motion.div>
                </>
            )}
        </motion.div>
    );
}
