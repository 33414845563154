import { useState } from "react";
import { Wopr } from "../data/data";
import Sroitel from "./ekran_1";
import Sportsmen from "./ekran_2";
import { motion } from "framer-motion";
import Policlinic from "./ekran_3";
import Istoric from "./ekran_4";
import Voditel from "./ekran_5";
import Ekonomist from "./ekran_6";
import Hozaistv from "./ekran_7";

// основная форма с вопросами
export default function Form({
    formInfo,
    setetape,
    end,
    setend,
}) {
    // состояние текущего шага
    const [step, setStep] = useState(0);
    // состояние экрана
    const [sost, setSost] = useState(false);
    // const [end, setEnd] = useState(false);
    // отбираем нужный элемент массива согластно текущему шагу
    let theme = Wopr[step];
    //

    function StepGo({ ip, val }) {
        let nameT = theme.name;
        // если пришёл результат то записываем его
        if (val) {
            // console.log(val);
            formInfo[nameT] = val;
        }
        setSost(false);
        // проверяем все ли вопросы пройдёны если да то на результат, иначе к следующему
        if (ip === 7) {
            setend(true);

            setetape(true);
        } else {
            setTimeout(() => {
                setStep(ip);
            }, 500);
        }
    }

    return (
        <>
            <div className="container">
                <div className="formOpr_step">
                    {/* табы всех вопросов */}
                    {Wopr.map((e, i) => (
                        <div
                            key={i}
                            // onClick={() =>
                            //     StepGo({ ip: i })
                            // }
                            style={{
                                background:
                                    step > i
                                        ? "#818181"
                                        : step >= i &&
                                          e.color,
                            }}
                            className={
                                step === i
                                    ? "formOpr_step_item active"
                                    : "formOpr_step_item"
                            }
                        >
                            {/* <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/gerb.png"
                                }
                                alt=""
                            /> */}
                            <p
                                style={{
                                    color:
                                        step >= i && "#fff",
                                }}
                            >
                                {i + 1}
                            </p>
                        </div>
                    ))}
                </div>
                {/* тело формы с вопросами */}
                <div className="formOpr_box">
                    <div className="formOpr_wopr__box">
                        <div
                            style={{
                                background: theme.color,
                            }}
                            className="formOpr_wopr__box_gerb"
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/gerb.png"
                                }
                                alt=""
                            />
                        </div>

                        <motion.div
                            key={step}
                            initial={{
                                opacity: 0.5,
                            }}
                            animate={{
                                opacity: 1,
                            }}
                            transition={{
                                duration: 2,
                                type: "spring",
                            }}
                            className="formOpr_wopr__box_text"
                        >
                            <h3>{theme.wopros}</h3>
                        </motion.div>
                    </div>
                    <p className="formOpr_box-var">
                        Выберите свой вариант ответа
                    </p>
                    {/* лист с вариантами ответов */}
                    <div
                        style={{
                            "--color": Wopr[step].color,
                        }}
                        className="formOpr_variant"
                    >
                        {Wopr.map((element, index) =>
                            element.war.map((e, i) => (
                                <motion.label
                                    className={
                                        step === index
                                            ? "visble"
                                            : "hidden"
                                    }
                                    key={
                                        "label" + index + i
                                    }
                                    id={"label" + index + i}
                                    htmlFor={
                                        element.name + i
                                    }
                                    onClick={() =>
                                        StepGo({
                                            ip: index + 1,
                                            val: e.bal,
                                        })
                                    }
                                    initial={{}}
                                    animate={{
                                        rotateX: [90, 0],
                                    }}
                                    transition={{
                                        duration: 0.3,
                                    }}
                                >
                                    <span
                                        className="gal"
                                        style={{
                                            "--colors":
                                                theme.color,
                                        }}
                                        color={theme.color}
                                    >
                                        <svg
                                            width="20"
                                            height="16"
                                            viewBox="0 0 20 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                className="gal_it"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M6.99892 11.8785L18.1504 0.727051L19.8474 2.42411L6.99892 15.2726L0.150391 8.42411L1.84745 6.72705L6.99892 11.8785Z"
                                            />
                                        </svg>
                                    </span>
                                    <input
                                        type="radio"
                                        defaultValue={e.bal}
                                        name={element.name}
                                        id={
                                            element.name + i
                                        }
                                    />
                                    <span dangerouslySetInnerHTML={{ __html: e.text }} className="text">
                                        {}
                                    </span>
                                </motion.label>
                            ))
                        )}
                    </div>
                    {window.screen.width<600 &&
                
                <motion.div
                    key={sost}
                    initial={
                        sost
                            ? {
                                translatez:-1,
                                  rotateY: "90deg",
                              }
                            : {
                                  rotateY: "0deg",
                              }
                    }
                    animate={
                        sost
                            ? {
                                translatez:-1,
                                  rotateY: "0deg",
                              }
                            : { translatez:-1, rotateY: "-90deg" }
                    }
                    transition={{
                        duration: 0.3,
                    }}
                    className="formOpr_cheel"
                >
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            `/img/${Wopr[step].name}.png`
                        }
                        alt=""
                    />
                </motion.div>}
                </div>
                {window.screen.width>600 &&
                
                <motion.div
                    key={sost}
                    initial={
                        sost
                            ? {
                                translatez:2,
                                  rotateY: "90deg",
                              }
                            : {
                                translatez:2,
                                  rotateY: "0deg",
                              }
                    }
                    animate={
                        sost
                            ? {
                                translatez:2,
                                  rotateY: "0deg",
                              }
                            : { translatez:2, rotateY: "-90deg" }
                    }
                    transition={{
                        duration: 0.3,
                    }}
                    className="formOpr_cheel"
                >
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            `/img/${Wopr[step].name}.png`
                        }
                        alt=""
                    />
                </motion.div>}
            </div>

            <div className="fon">
              {/* в зависимости от текущего вопроса подставляем нужный фон */}
                {step === 0 && (
                    <Sportsmen
                        sost={sost}
                        setSost={setSost}
                        its={Wopr[0]}
                    />
                )}

                {step === 1 && <Sroitel sost={sost}
                        setSost={setSost}
                        its={Wopr[1]} />}
                {step === 2 && (
                    <Policlinic
                        its={Wopr[2]}
                        sost={sost}
                        setSost={setSost}
                    />
                )}
                {step === 3 && (
                    <Istoric
                        its={Wopr[2]}
                        sost={sost}
                        setSost={setSost}
                    />
                )}
                {step === 4 && (
                    <Voditel
                        its={Wopr[2]}
                        sost={sost}
                        setSost={setSost}
                    />
                )}
                {step === 5 && (
                    <Ekonomist
                        its={Wopr[2]}
                        sost={sost}
                        setSost={setSost}
                    />
                )}
                {step === 6 && (
                    <Hozaistv
                        its={Wopr[2]}
                        sost={sost}
                        setSost={setSost}
                    />
                )}
            </div>
        </>
    );
}
