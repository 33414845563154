// список основных анимаций используемых в проекте
import {
    motion,
} from "framer-motion";
// выезд элемента с низу

export const AniBot = ({ state, clas, end, child,time }) => {
    return (
        <motion.div
            
            initial={state?{ bottom: "-100%", scale: 0.1 }:{ bottom: end, scale: 1 }}
            animate={state?{bottom: end, scale: 1 }:{  bottom: "-100%", scale: 0.1 }}
            transition={{
                duration: time,
                type: "spring",
            }}
            className={"box_img " + clas}
        >
            {child}
        </motion.div>
    );
};
// выезд элемента с вурху

export const AniTop= ({ state, clas, end, child,time }) => {
    return (
        <motion.div
            initial={state?{ top: "-100%", scale: 0.1 }:{ top: end, scale: 1 }}
            animate={state?{ top: end, scale: 1 }:{ top: "-100%", scale: 0.1 }}
            transition={{
                duration: time,
                type: "spring",
                damping: 10,
                stiffness: 80,
            }}
            className={"box_img " + clas}
        >
            {child}
        </motion.div>
    );
};
// выезд элемента с лева

export const AniLeft= ({ state, clas, end, child,time }) => {
    return (
        <motion.div
            initial={state?{ left: "-100%", scale: 0.1 }:{ left: end, scale: 1 }}
            animate={state?{ left: end, scale: 1 }:{ left: "-100%", scale: 0.1 }}
            transition={{
                duration: time,
                type: "spring",
                
            }}
            className={"box_img " + clas}
        >
            {child}
        </motion.div>
    );
};
// выезд элемента с права

export const AniRight= ({ state, clas, end, child,time }) => {
    return (
        <motion.div
            initial={state?{ right: "-100%", scale: 0.1 }:{ right: end, scale: 1 }}
            animate={state?{ right: end, scale: 1 }:{ right: "-100%", scale: 0.1 }}
            transition={{
                duration: time,
                type: "spring",
            }}
            className={"box_img " + clas}
        >
            {child}
        </motion.div>
    );
};
// увеличивание элемента с 0 до десктопа

export const AniSceil= ({ state, clas,  child,time }) => {
    return (
        <motion.div
        initial={state?{ scale: 0 }:{ scale: 1 }}
        animate={state?{ scale: 1 }:{ scale: 0 }}
            transition={{
                duration: time,
                type: "spring",
            }}
            className={"box_img " + clas}
        >
            {child}
        </motion.div>
    );
};
// разворот элемента с права

export const AniRigtRevert = ({ state, clas, child,time }) => {
    return (
        <motion.div
        initial={state?{
            rotateY: "90deg",
            x: 200,
        }:{ rotateY: "0deg", x: 0 }}
        animate={state?{ rotateY: "0deg", x: 0 }:{
            rotateY: "90deg",
            x: 200,
        }}
        
            transition={{
                duration: time,
                type: "spring",
            }}
            className={"box_img " + clas}
        >
            {child}
        </motion.div>
    );
};

// подьём элемента с низу

export const AniAppToBot= ({ state, clas, child,time }) => {
    return (
        <motion.div
        initial={state?{rotateX:"90deg",translateY:268 }:{ rotateX:"0",translateY:0}}
            animate={state?{ rotateX:"0",translateY:0}:{ rotateX:"90deg",translateY:268  }}
            transition={{
                duration: time,
                type: "",
            }}
            className={"box_img " + clas}
        >
            {child}
        </motion.div>
    );
};