import { motion } from "framer-motion";
import {
    AniAppToBot,
    AniBot,
    AniLeft,
    AniRight,
} from "./animation";
import { useState } from "react";

export default function Ekonomist({ its, sost, setSost }) {
    // переменная состояния видимости экрана 

    const [visibles, setvisibles] = useState(false);

    const variants = {
        visible: { background: "#1833A0" },
        hidden: { background: "#A0BFFD" },
    };
    // проверка сотояния видимости чтобы предотвратить постоянный вызов функции видимости 

    if (!visibles) {
        function onAni() {
            setTimeout(() => {
                setSost(true);
                setvisibles(true);
            }, 500);
        }

        let options = {
            threshold: [0.5],
        };
        let observer = new IntersectionObserver(
            onAni,
            options
        );
        let elements = document.querySelectorAll(".ecran");
        for (let elm of elements) {
            observer.observe(elm);
        }
    }
    // временные интервалы

    let time = 1;
    let time2 = time * 2;
    let time3 = time * 3;
    let time4 = time * 4;
    let time5 = time * 5;
    return (
        <motion.div
            animate={!sost ? "visible" : "hidden"}
            variants={variants}
            transition={{
                duration: 1,
            }}
            className="ecran ekonomist"
        >
            {/* начинаем отрисовку элемента после подтверждения видимости экрана */}

            {visibles && (
                <>
                    <AniAppToBot
                        time={time}
                        state={sost}
                        clas="e1"
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e6/e1.png"
                                }
                                alt=""
                            />
                        }
                    />

                    <AniLeft
                        time={time}
                        state={sost}
                        end={"0%"}
                        clas="e2"
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e6/e2.png"
                                }
                                alt=""
                            />
                        }
                    />
                    <AniBot
                        time={time3}
                        state={sost}
                        clas="e3 tick"
                        end={"-18%"}
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e6/e3.png"
                                }
                                alt=""
                            />
                        }
                    />
                    <AniRight
                        time={time3}
                        state={sost}
                        clas="e4 vert"
                        end={"8%"}
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e6/e4.png"
                                }
                                alt=""
                            />
                        }
                    />
                    <AniAppToBot
                        time={time}
                        state={sost}
                        clas="e5"
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e6/e5.png"
                                }
                                alt=""
                            />
                        }
                    />
                    <AniRight
                        time={time3}
                        state={sost}
                        clas="e6 reVert"
                        end={"-2%"}
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e6/e4.png"
                                }
                                alt=""
                            />
                        }
                    />
                    <AniBot
                        time={time3}
                        state={sost}
                        clas="e7"
                        end={"36.1%"}
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e6/e6.png"
                                }
                                alt=""
                            />
                        }
                    />
                    {/* подложка */}
                    {/* <motion.div
                        initial={{ background: "rgb(253, 119, 148)" }}
                        animate={{ background: "#98093F" }}
                        transition={{
                            duration: 1,
                        }}
                        className="podloj"
                    ></motion.div> */}
                </>
            )}
        </motion.div>
    );
}
