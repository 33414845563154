import {
    motion,
} from "framer-motion";
import { AniBot, AniLeft, AniRight, AniRigtRevert, AniSceil, AniTop } from "./animation";
import { useState } from "react";



export default function Istoric({ its, sost, setSost }) {
    // переменная состояния видимости экрана 

    const [visibles, setvisibles] = useState(false);

    const variants = {
        visible: { background: "#663398"},
        hidden: { background: "#C7ABF4" },
    };
    // проверка сотояния видимости чтобы предотвратить постоянный вызов функции видимости 

    if (!visibles) {
        function onAni() {
            setTimeout(() => {
                setSost(true);
                setvisibles(true);
            }, 500);
        }

        let options = {
            threshold: [0.5],
        };
        let observer = new IntersectionObserver(
            onAni,
            options
        );
        let elements = document.querySelectorAll(".ecran");
        for (let elm of elements) {
            observer.observe(elm);
        }
    }
    // временные интервалы
 
    let time = 1;
    let time2 = time*2;
    let time3 = time*3;
    let time5 = time*5;
    return (
        <motion.div
            animate={!sost ? "visible" : "hidden"}
            variants={variants}
            transition={{
                duration: 1,
            }}
            className="ecran istoric"
        >
            {/* начинаем отрисовку элемента после подтверждения видимости экрана */}

            {visibles && (
                <>
                    <AniBot
                    
                    time={time}
                    state={sost}
                    clas="e1"
                    end={"-1%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e4/el1.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniTop
                    
                    time={time}
                        state={sost}
                        clas="e2"
                        end={"9%"}
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e4/el2.png"
                                }
                                alt=""
                            />
                        }
                    
                    />
                    <AniTop
                    
                    time={time}
                        state={sost}
                        clas="e3"
                        end={"7%"}
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e4/el4.png"
                                }
                                alt=""
                            />
                        }
                    
                    />
                    <AniTop
                    
                    time={time}
                        state={sost}
                        clas="e4"
                        end={"7%"}
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e4/el2.png"
                                }
                                alt=""
                            />
                        }
                    
                    />
                    <AniLeft
                    
                    time={time}
                        state={sost}
                        clas="e5"
                        end={"-14.5%"}
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e4/el5.png"
                                }
                                alt=""
                            />
                        }
                    
                    />
                    <AniTop
                    
                    time={time}
                        state={sost}
                        clas="e6"
                        end={"36.5%"}
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e4/el3.png"
                                }
                                alt=""
                            />
                        }
                    
                    />
                    <AniRigtRevert
                   time={time}
                   state={sost}
                   clas="e7"
                   child={
                       <img
                       src={
                           process.env.PUBLIC_URL +
                           "/img/e4/el6.png"
                       }
                       alt=""
                   />
                    }
                   />
                    <AniTop
                    
                    time={time}
                        state={sost}
                        clas="e8"
                        end={"0%"}
                        child={
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/e4/el7.png"
                                }
                                alt=""
                            />
                        }
                    
                    />
                    <AniBot
                    
                    time={time}
                    state={sost}
                    clas="e9"
                    end={"-1%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e4/el8.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={time}
                    state={sost}
                    clas="e10"
                    end={"-1%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e4/el9.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={time3}
                    state={sost}
                    clas="e11"
                    end={"-1%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e4/el10.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={time2}
                    state={sost}
                    clas="e12"
                    end={"-1%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e4/el10.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniBot
                    
                    time={time2}
                    state={sost}
                    clas="e13"
                    end={"-1%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e4/el11.png"
                            }
                            alt=""
                        />
                    }
                    />
                    <AniRight
                    
                    time={time2}
                    state={sost}
                    clas="e14"
                    end={"18%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e4/el12.png"
                            }
                            alt=""
                        />
                    }
                    />
                     <AniLeft
                    
                    time={time2}
                    state={sost}
                    clas="e15"
                    end={"12%"}
                    child={
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/e4/el13.png"
                            }
                            alt=""
                        />
                    }
                    />
                    {/* подложка */}
                    {/* <motion.div
                        initial={{ background: "rgb(255, 190, 85)" }}
                        animate={{ background: "#FF810B" }}
                        transition={{
                            duration: 1,
                        }}
                        className="podloj"
                    ></motion.div> */}
                </>
            )}
        </motion.div>
    );
}
