import React from 'react';
import ReactDOM from 'react-dom/client';
import Main from './pages';
import "./style/style.css";
import "./font/stylesheet.css";


// инициализируем контейнер под React
const root = ReactDOM.createRoot(document.getElementById('root'));

// производим render  в инициализированный контейнер
root.render(
  <React.StrictMode>
    <Main/>
  </React.StrictMode>
);
